.CardTitle {
  opacity: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0.1) 100%
  ) !important;
  transition: 0.3s;
}

.Card {
  cursor: pointer;
  transition: 350ms ease-in-out;
  &:hover {
    transition: 350ms ease-in-out;
    scale: 1.015;
    border: solid 1px #5b5b5b;
  }
}

.LikeButton,
.RemixButton {
  cursor: pointer;
}

.LikeButton {
  transition: 350ms;
  &:hover {
    color: #ff0000;
  }
}

.RemixButton {
  transition: 350ms;
  &:hover {
    color: #ffbf00;
  }
}
